<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>조회기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 7 : 6" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>작업지시일</th>
              <th>생산제품</th>
              <th>납품업체</th>
              <th>작업수량(단위)</th>
              <th>공정상태</th>
              <th>Lot번호</th>
              <th v-if="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(lot, index) in lots"
              :key="lot.id"
              @click="selectRow(index)"
              :class="{ active: selectedIndex == index }"
            >
              <td>{{ lot.input_date | formatDateNoHours }}</td>
              <td class="text_left">
                {{ findInfoFromId(products, lot.product_id).name }}
              </td>
              <td class="text_left">
                {{
                  lot.company_id != null
                    ? findInfoFromId(companys, lot.company_id).name
                    : '자체생산'
                }}
              </td>
              <td class="text_right">
                <b>{{ `${$makeComma(lot.quantity)}` }}</b>
                {{
                  `(${
                    findInfoFromId(
                      units,
                      findInfoFromId(products, lot.product_id).stock_unit_id,
                    ).name
                  })`
                }}
              </td>
              <td>
                <span>{{
                  findInfoFromId(lot_types, lot.lot_type_id).name
                }}</span>
                <button
                  class="btn_tbl"
                  @click="
                    goBomManagementPage(
                      findInfoFromId(products, lot.product_id).id,
                    )
                  "
                >
                  BOM확인
                </button>
              </td>
              <td>
                <span>{{
                  findInfoFromId(lot_types, lot.lot_type_id).detail !=
                  'wait process'
                    ? lot.lot_number
                    : ''
                }}</span>
                <button
                  v-if="
                    findInfoFromId(lot_types, lot.lot_type_id).detail !=
                      'wait process'
                  "
                  class="btn_tbl"
                  @click="
                    trackingLotNum(
                      findInfoFromId(lot_types, lot.lot_type_id).detail !=
                        'wait process'
                        ? lot.lot_number
                        : '',
                    )
                  "
                >
                  추적
                </button>
              </td>
              <td v-if="managementMode" @click="ShowModal(index)">
                <button class="tbl_delete_btn">
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="order_info aside_cont">
        <h5>작업지시 정보</h5>
        <div>
          <div class="input_text">
            <label>작업지시일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              id="input_date"
              :value="managementData.input_date"
              :disabled="managementData.input_date == null || !managementMode"
              :readonly="!managementMode || selectedIndex == -1"
              @change="
                setInputDate($event);
                touchflag = true;
              "
              v-if="showSearchDate"
            />
          </div>
          <div class="input_text">
            <label>작업 완료일정</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              id="deadline_date"
              :value="managementData.deadline_date"
              :disabled="managementData.input_date == null || !managementMode"
              :readonly="!managementMode || selectedIndex == -1"
              @change="
                setDeadLineDate($event);
                touchflag = true;
              "
              v-if="showSearchDate"
            />
          </div>
        </div>
        <div>
          <div class="input_search">
            <label>생산제품</label>
            <input
              type="text"
              placeholder="제품명 검색"
              :value="
                managementData.product_id == null
                  ? product_search_text
                  : findInfoFromId(products, managementData.product_id).name
              "
              @input="handleProductInput($event)"
              readonly
            />
            <button
              type="button"
              class="search_btn"
              @click="showProductSearch = true"
              v-if="
                selectedIndex != -1 &&
                  managementData.sales_id == null &&
                  managementMode
              "
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div
            class="input_search input_search2"
            :class="{
              input_search3:
                selectedIndex != -1 &&
                managementData.sales_id == null &&
                managementData.company_id != null &&
                managementMode,
            }"
          >
            <label>납품처</label>
            <input
              type="text"
              :value="
                managementData.company_id == null
                  ? '자체생산'
                  : findInfoFromId(companys, managementData.company_id).name
              "
              readonly
            />
            <button
              type="button"
              class="btn_del"
              @click="
                managementData.company_id = null;
                touchflag = true;
              "
              v-if="
                selectedIndex != -1 &&
                  managementData.sales_id == null &&
                  managementData.company_id != null &&
                  managementMode
              "
            >
              <i class="fa fa-times"></i>
            </button>
            <button
              type="button"
              class="search_btn"
              @click="
                showCompanySearch = true;
                touchflag = true;
              "
              v-if="
                selectedIndex != -1 &&
                  managementData.sales_id == null &&
                  managementMode
              "
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <div class="input_text">
            <label
              >작업수량({{
                managementData.product_id == undefined
                  ? '단위'
                  : findInfoFromId(
                      units,
                      findInfoFromId(products, managementData.product_id)
                        .stock_unit_id,
                    ).name
              }})</label
            >
            <input
              class="text_right"
              :readonly="managementData.product_id == null || !managementMode"
              type="text"
              inputmode="decimal"
              placeholder="0"
              :value="$makeComma(managementData.quantity)"
              @input="
                $event => {
                  $inputNumber($event, managementData, 'quantity');
                  touchflag = true;
                  $store.commit('setManagementGroupMaterialToWorkOrder', []);
                }
              "
            />
          </div>
          <div class="input_text">
            <label>LOT 번호</label>
            <input
              type="text"
              id="lotNum"
              readonly
              placeholder=""
              :value="
                findInfoFromId(lot_types, managementData.lot_type_id).detail !=
                'wait process'
                  ? managementData.lot_number
                  : ''
              "
            />
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>공정상태</label>
            <input
              type="text"
              id="status"
              readonly
              placeholder=""
              :value="
                findInfoFromId(lot_types, managementData.lot_type_id).name
              "
            />
          </div>
          <div class="passive_check">
            <label>자재 수동투입</label>
            <div class="input_checkbox">
              <label
                for="checkbox2"
                class="chk_box"
                :class="{
                  active: managementData.custom_input_yn,
                }"
                :readonly="!managementMode"
                :disabled="!managementMode"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox2"
                @click="
                  managementData.custom_input_yn = !managementData.custom_input_yn;
                  touchflag = true;
                "
                :readonly="!managementMode"
                :disabled="!managementMode"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="tank_check">
            <label>투입 탱크</label>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>탱크번호</th>
                    <th>탱크1</th>
                    <th>탱크2</th>
                    <th>탱크3</th>
                    <th>탱크4</th>
                  </tr>
                </thead>
                <tbody v-if="managementMode && selectedIndex != -1">
                  <tr>
                    <td>투입</td>
                    <td>
                      <div class="input_checkbox">
                        <label
                          class="chk_box"
                          :for="`checkbox211`"
                          :id="`checkbox211`"
                          :class="{ active: managementLotTank.tank1 }"
                          @click="
                            managementLotTank.tank1 = !managementLotTank.tank1;
                            touchflag = true;
                          "
                        >
                          <i class="fa fa-check"></i>
                        </label>
                        <input type="checkbox" />
                      </div>
                    </td>
                    <td>
                      <div class="input_checkbox">
                        <label
                          class="chk_box"
                          :for="`checkbox212`"
                          :id="`checkbox212`"
                          :class="{ active: managementLotTank.tank2 }"
                          @click="
                            managementLotTank.tank2 = !managementLotTank.tank2;
                            touchflag = true;
                          "
                        >
                          <i class="fa fa-check"></i>
                        </label>
                        <input type="checkbox" />
                      </div>
                    </td>
                    <td>
                      <div class="input_checkbox">
                        <label
                          class="chk_box"
                          :for="`checkbox213`"
                          :id="`checkbox213`"
                          :class="{ active: managementLotTank.tank3 }"
                          @click="
                            managementLotTank.tank3 = !managementLotTank.tank3;
                            touchflag = true;
                          "
                        >
                          <i class="fa fa-check"></i>
                        </label>
                        <input type="checkbox" />
                      </div>
                    </td>
                    <td>
                      <div class="input_checkbox">
                        <label
                          class="chk_box"
                          :for="`checkbox214`"
                          :id="`checkbox214`"
                          :class="{ active: managementLotTank.tank4 }"
                          @click="
                            managementLotTank.tank4 = !managementLotTank.tank4;
                            touchflag = true;
                          "
                        >
                          <i class="fa fa-check"></i>
                        </label>
                        <input type="checkbox" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td>투입</td>
                    <td>{{ managementLotTank.tank1 ? 'Y' : 'N' }}</td>
                    <td>{{ managementLotTank.tank2 ? 'Y' : 'N' }}</td>
                    <td>{{ managementLotTank.tank3 ? 'Y' : 'N' }}</td>
                    <td>{{ managementLotTank.tank4 ? 'Y' : 'N' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          v-if="
            findInfoFromId(products, managementData.product_id).product_group_yn
          "
        >
          <div class="input_text package">
            <label>포장 지시</label>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>제품명</th>
                    <th>규격</th>
                    <th>포장 수량</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in managementData.lot_detail"
                    :key="item.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text_left">
                      {{
                        findInfoFromId(products, item.member_product_id).name
                      }}
                    </td>
                    <td class="text_left">
                      {{
                        findInfoFromId(products, item.member_product_id)
                          .standard
                      }}
                    </td>
                    <td class="input_td">
                      <input
                        :disabled="!managementMode || selectedIndex == -1"
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(item.quantity)"
                        @input="
                          $inputNumber($event, item, 'quantity');
                          touchflag = true;
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="input_text">
          <label>작업주의사항</label>
          <textarea
            v-model="managementData.detail"
            @input="touchflag = true"
            :disabled="selectedIndex == -1 || !managementMode"
          ></textarea>
        </div>
        <h5>투입 항목</h5>
        <h6>투입 원자재</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup :class="{ custom: managementData.custom_input_yn }">
              <col
                v-for="(n, index) in managementData.custom_input_yn ? 5 : 6"
                :key="index"
              />
            </colgroup>
            <thead>
              <tr>
                <th>원자재명</th>
                <th>규격</th>
                <th v-if="!managementData.custom_input_yn">개별 소요량</th>
                <th>작업소요량</th>
                <th>단위</th>
                <th class="tooltip">산출재고</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(mat, index) in managementData.custom_input_yn
                  ? __fixMaterial
                  : group_material_list"
                :key="mat.id"
              >
                <td class="text_left">
                  {{ findInfoFromId(materials, mat.material_id).name }}
                  <button
                    class="btn_floating_search"
                    v-if="
                      findInfoFromId(materials, mat.material_id)
                        .material_group_yn
                    "
                    @click="
                      target_group_id = mat.material_id;
                      target_need_quantity = calNeedQuantity(mat, 1);
                      showGroupSelect = true;
                    "
                  >
                    <img src="@/assets/images/icon/icon-floating-search.png" />
                  </button>
                </td>
                <td class="text_left">
                  {{ findInfoFromId(materials, mat.material_id).standard }}
                </td>
                <td class="text_right" v-if="!managementData.custom_input_yn">
                  {{
                    mat.group_material_id == null
                      ? $makeComma($makeNumber(mat.quantity)) +
                        (mat.percent_yn ? '%' : '')
                      : ''
                  }}
                </td>
                <td class="text_right" v-if="!managementData.custom_input_yn">
                  {{ $makeComma(calNeedQuantity(mat, 1)) }}
                </td>
                <td v-else class="input_td">
                  <input
                    type="text"
                    inputmode="decimal"
                    :value="$makeComma(mat.quantity)"
                    @input="
                      $inputNumber($event, mat, 'quantity');
                      touchflag = true;
                    "
                    :readonly="!managementMode || selectedIndex == -1"
                    :disabled="!managementMode || selectedIndex == -1"
                  />
                </td>
                <td>
                  {{
                    findInfoFromId(
                      units,
                      findInfoFromId(materials, mat.material_id).using_unit_id,
                    ).name
                  }}
                </td>
                <td class="text_right">
                  {{ $makeComma(getMaterialCalQuantity(index)) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h6>투입 반제품&제품</h6>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup :class="{ custom: managementData.custom_input_yn }">
              <col
                v-for="(n, index) in managementData.custom_input_yn ? 5 : 6"
                :key="index"
              />
            </colgroup>
            <thead>
              <tr>
                <th>제품명</th>
                <th>규격</th>
                <th v-if="!managementData.custom_input_yn">개별 소요량</th>
                <th>작업 소요량</th>
                <th>단위</th>
                <th>산출재고</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(pro, index) in managementData.custom_input_yn
                  ? __fixProduct
                  : group_product_list"
                :key="pro.id"
              >
                <td class="text_left">
                  {{ findInfoFromId(products, pro.product_id).name }}
                </td>
                <td class="text_left">
                  {{ findInfoFromId(products, pro.product_id).standard }}
                </td>
                <td class="text_right" v-if="!managementData.custom_input_yn">
                  {{ $makeComma($makeNumber(pro.quantity)) }}
                </td>
                <td class="text_right" v-if="!managementData.custom_input_yn">
                  {{
                    $makeComma(
                      $makeNumber(
                        $decimalMul(
                          $makeNumber(pro.quantity),
                          $makeNumber(managementData.quantity),
                        ),
                      ),
                    )
                  }}
                </td>
                <td v-else class="input_td">
                  <input
                    type="text"
                    inputmode="decimal"
                    :value="$makeComma(pro.quantity)"
                    @input="
                      $inputNumber($event, pro, 'quantity');
                      touchflag = true;
                    "
                    :readonly="!managementMode || selectedIndex == -1"
                    :disabled="!managementMode || selectedIndex == -1"
                  />
                </td>
                <td>
                  {{
                    findInfoFromId(
                      units,
                      findInfoFromId(products, pro.product_id).stock_unit_id,
                    ).name
                  }}
                </td>
                <td class="text_right">
                  {{ $makeComma(getProductCalQuantity(index)) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="btn_wrap">
        <button
          v-if="$makeNumber(managementData.quantity) > 0 && managementMode"
          class="btn_sub2"
          :disabled="isValidModify"
          @click="submitForm()"
        >
          작업 수정
        </button>
      </div>
    </div>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :groupType="1"
      :pre_search_value="product_search_text"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></resource-search>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deleteLot($event)"
    ></two-button-modal>
    <resource-group-select
      v-if="showGroupSelect"
      :group_resource="target_group_id"
      :goal_value="target_need_quantity"
      @onclose="showGroupSelect = false"
      @onselect="
        $event => {
          setGroupMaterial($event);
          showGroupSelect = false;
        }
      "
    >
    </resource-group-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import ResourceGroupSelect from '@/layouts/components/search-popup/ResourceGroupSelect';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { yyyymmdd } from '@/utils/func';
import PriceMixin from '@/mixins/material_price.js';
import routes from '@/routes/routes';

export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin],
  components: {
    ResourceSearch,
    CompanySearch,
    TwoButtonModal,
    ResourceGroupSelect,
  },
  data() {
    return {
      //resource search modal parameter
      showProductSearch: false,
      product_search_text: '',

      //local flag
      showSearchDate: false,

      //search popup
      showSalesSearch: false,
      showCompanySearch: false,

      //group_select
      target_group_id: null,
      target_need_quantity: null,
      showGroupSelect: false,

      bom_parents_id_count: -1,

      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,

      touchflag: false,
    };
  },
  computed: {
    ...mapGetters({
      lot_types: 'getLotType',
      products: 'getProduct',
      product_groups: 'getProductGroup',
      materials: 'getMaterial',
      __fixMaterial: 'getManagementFixExpectMaterial',
      __fixProduct: 'getManagementFixExpectProduct',
      units: 'getUnitCodes',
      unit_conversion: 'getUnitConversion',
      sales: 'getSales',
      materialGroups: 'getMaterialGroup',
      managementData: 'getManagementDataFromWorkOrder',
      seller_companys: 'getSellerCompany',
      companys: 'getCompany',
      boms: 'getBom',
      process: 'getProcess',
      material_stock_list: 'getMaterialTotalStock',
      product_stock_list: 'getProductTotalStock',
      material_occupied_stock: 'getMaterialOccupiedStock',
      product_occupied_stock: 'getProductOccupiedStock',
      selected_product_list: 'getManagementSelectedSalesProducts',
      managementMaterialGroup: 'getManagementGroupMaterialFromWorkOrder',
      start_date: 'getStartDateFromWorkOrder',
      end_date: 'getEndDateFromWorkOrder',
      date: 'getDateFromWorkOrder',
      boxs: 'getBoxs',
      lotsSrc: 'getLot',
      selectedIndex: 'getSelectedIndexFromWorkOrder',
      managementMode: 'getManagementModeFromWorkOrderPage',
      managementLotTank: 'getManagementLotTank',
    }),
    lots() {
      if (this.lotsSrc.length < 1) {
        return [];
      } else {
        return this.lodash
          .clonedeep(this.lotsSrc)
          .sort((a, b) => new Date(b.input_date) - new Date(a.input_date))
          .filter(
            x =>
              new Date(x.input_date) <= new Date(this.end_date) &&
              new Date(x.input_date) >= new Date(this.start_date),
          );
      }
    },
    selected_product_list_info() {
      let indexTemp = -1;
      this.selected_product_list.forEach((el, index) => {
        if (el.product_id == this.managementData.product_id) {
          indexTemp = index;
        }
      });
      return {
        length: this.selected_product_list.length,
        selected_index: indexTemp,
      };
    },
    group_material_list() {
      if (this.managementData.bom_id == null) return [];
      const { bom_process } = this.findInfoFromId(
        this.boms,
        this.managementData.bom_id,
      );
      let group_list = [];

      bom_process.forEach(process => {
        const filter_list = this.lodash.clonedeep(
          process.bom_resource.filter(x => x.material_id != null),
        );
        filter_list.forEach(element => {
          let already_exist = group_list.find(
            x => x.material_id == element.material_id,
          );
          if (already_exist != undefined) {
            already_exist.quantity = this.$decimalAdd(
              already_exist.quantity,
              element.quantity,
            );
          } else {
            group_list.push(element);
          }
        });
      });
      if (this.managementMaterialGroup.length > 0) {
        const mapGroupMaterial = this.managementMaterialGroup.map(
          x => x.group_material_id,
        );
        group_list = group_list.filter(
          x => !mapGroupMaterial.includes(x.material_id),
        );
        return group_list.concat(this.managementMaterialGroup);
      }
      return group_list;
    },
    group_product_list() {
      if (this.managementData.bom_id == null) return [];
      const { bom_process } = this.findInfoFromId(
        this.boms,
        this.managementData.bom_id,
      );
      let group_list = [];
      bom_process.forEach(process => {
        const filter_list = this.lodash.clonedeep(
          process.bom_resource.filter(x => x.product_id != null),
        );
        filter_list.forEach(element => {
          let already_exist = group_list.find(
            x => x.product_id == element.product_id,
          );
          if (already_exist != undefined) {
            already_exist.quantity = this.$decimalAdd(
              already_exist.quantity,
              element.quantity,
            );
          } else {
            group_list.push(element);
          }
        });
      });
      return group_list;
    },
    checkValid() {
      let cnt = 0;
      this.group_material_list.forEach((el, index) => {
        if (this.getMaterialCalQuantity(index) < 0) {
          cnt++;
        }
      });
      if (cnt < 1) {
        this.group_product_list.forEach((el, index) => {
          if (this.getProductCalQuantity(index) < 0) {
            cnt++;
          }
        });
        if (cnt < 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isValidModify() {
      if (this.managementMode) {
        if (this.touchflag) {
          return false; //활성화
        } else {
          return true;
        }
      } else return true;
    },
  },
  methods: {
    setGroupMaterial(arg) {
      let listTemp = this.lodash.clonedeep(this.managementMaterialGroup);
      listTemp = listTemp.filter(
        x => x.group_material_id != this.target_group_id,
      );
      arg.forEach(el => {
        const groupM = this.lodash.clonedeep(
          this.group_material_list.find(
            x => x.material_id == this.target_group_id,
          ),
        );

        groupM.id += 1000 + Math.floor(Math.random() * 10000 + 100000);
        groupM.material_id = el.id;
        groupM.name = el.name;
        groupM.quantity = this.$makeNumber(el.quantity);
        groupM.group_material_id = this.target_group_id;
        listTemp.push(groupM);
      });
      console.log(arg);
      this.$store.commit('setManagementGroupMaterialToWorkOrder', listTemp);
    },
    setExpectGroupMaterial(arg, parent_id) {
      const listTemp = this.lodash.clonedeep(this.managementMaterialGroup);
      arg.forEach((el, index) => {
        const groupM = this.lodash.clonedeep(
          this.group_material_list.find(x => x.material_id == parent_id),
        );
        groupM.id += 1000 + index;
        groupM.material_id = el.material_id;
        groupM.name = this.findInfoFromId(this.materials, el.material_id).name;
        groupM.quantity = el.quantity;
        groupM.group_material_id = parent_id;
        listTemp.push(groupM);
      });
      console.log(arg);
      this.$store.commit('setManagementGroupMaterialToWorkOrder', listTemp);
    },
    calNeedQuantity(resource, resource_type) {
      if (resource.group_material_id != undefined) {
        return resource.quantity;
      }
      if (resource.percent_yn) {
        let resourceInfo = null;
        if (resource_type == 1) {
          resourceInfo = this.findInfoFromId(
            this.materials,
            resource.material_id,
          );
        } else {
          resourceInfo = this.findInfoFromId(
            this.products,
            resource.product_id,
          );
        }

        const productInfo = this.findInfoFromId(
          this.products,
          this.managementData.product_id,
        );
        let cvr_ratio = null;
        if (resource_type == 1) {
          cvr_ratio = this.unit_conversion.find(
            x =>
              x.in_unit_id == resourceInfo.using_unit_id &&
              x.out_unit_id == productInfo.stock_unit_id,
          );
        } else {
          cvr_ratio = this.unit_conversion.find(
            x =>
              x.in_unit_id == resourceInfo.stock_unit_id &&
              x.out_unit_id == productInfo.stock_unit_id,
          );
        }

        if (cvr_ratio != undefined) {
          return this.$decimalMul(
            this.$decimalMul(
              this.$makeNumber(this.managementData.quantity),
              this.$makeNumber(this.$decimalDiv(resource.quantity, 100)),
            ),
            cvr_ratio.cvr_ratio,
          );
        } else {
          return this.$decimalMul(
            this.$makeNumber(this.managementData.quantity),
            this.$decimalDiv(resource.quantity, 100),
          );
        }
      } else {
        return this.$makeNumber(
          this.$decimalMul(
            this.$makeNumber(resource.quantity),
            this.$makeNumber(this.managementData.quantity),
          ),
        );
      }
    },
    async deleteLot(arg1) {
      console.log(arg1);
      if (arg1 == undefined) return;

      //수주 삭제
      if (
        this.managementData.lot_type_id !=
        this.lot_types.find(x => x.detail == 'wait process').id
      ) {
        this.hideSpinner();
        this.CloseModal();
        this.openOneButtonModal(
          '삭제 불가',
          '진행중 또는 완료된 작업지시는 삭제할 수 없습니다.',
        );
        return;
      }
      this.showSpinner();
      this.$store
        .dispatch('DELETE_LOT', this.managementData.id)
        .then(() => {
          this.FETCH_LOT_DAY();
          this.selectRow(-1);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToWorkOrder', e.target.value);
          this.selectRow(-1);
          this.FETCH_LOT_DAY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToWorkOrder', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToWorkOrder', e.target.value);
          this.selectRow(-1);
          this.FETCH_LOT_DAY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToWorkOrder', yyyymmdd(new Date()));
      }
    },
    selectRow(index) {
      this.touchflag = false;
      this.$store.commit('setSelectedIndexToWorkOrder', index);
      if (index != -1) {
        this.FETCH_WITH_PAYLOAD(
          'FETCH_LOT_TANK_BY_LOT_ID',
          this.lots[index].id,
          '투입탱크',
        );
        this.$store.commit(
          'setManagementDataToWorkOrder',
          this.lodash.clonedeep(this.lots[index]),
        );
        if (this.managementData.lot_type_id == 1) {
          this.selectProduct({ id: this.managementData.product_id });
        } else {
          this.selectProductFromSelectRow({
            id: this.managementData.product_id,
          });
        }
        // this.selectProduct({ id: this.managementData.product_id });

        const group_material_list_filter_group_material = this.group_material_list.filter(
          x =>
            this.findInfoFromId(this.materials, x.material_id)
              .material_group_yn == true,
        );
        if (group_material_list_filter_group_material.length > 0) {
          console.log(
            `this.group_material_list.filter(x => x.material_group_yn == true)
            .length > 0`,
            group_material_list_filter_group_material,
          );

          const map_group_materials = group_material_list_filter_group_material.map(
            x => x.material_id,
          );
          console.log('map_group_materials', map_group_materials);
          map_group_materials.forEach(element => {
            const filterMemberMaterials = this.materialGroups
              .filter(x => x.group_material_id == element)
              .map(x => x.member_material_id);
            const mapMemberExpectGroupList = this.managementData.lot_resource_expect_list.filter(
              x => filterMemberMaterials.includes(x.material_id),
            );
            this.setExpectGroupMaterial(mapMemberExpectGroupList, element);
          });
        }
        // if(this.managementData.lot_resource_expect_list)@@@@
      } else {
        this.$store.commit('setManagementLotTank', {
          lot_id: null,
          tank1: false,
          tank2: false,
          tank3: false,
          tank4: false,
        });
        this.$store.commit('setManagementDataToWorkOrder', {
          id: null,
          input_date: null,
          deadline_date: null,
          product_id: null,
          bom_id: null,
          quantity: 0,
          sales_id: null,
          company_id: null,
          detail: '',
        });
      }
    },
    getMaterialCalQuantity(index) {
      const stock_quantity = this.findInfoFromId(
        this.material_stock_list,
        this.group_material_list[index].material_id,
      ).stock_quantity;
      const occupied_stock = this.findInfoFromId(
        this.material_occupied_stock,
        this.group_material_list[index].material_id,
      ).quantity;

      const expect = this.lots[
        this.selectedIndex
      ].lot_resource_expect_list.find(
        x => x.material_id == this.group_material_list[index].material_id,
      );
      let expect_stock = 0;
      if (expect != undefined) {
        expect_stock = expect.quantity;
      }
      if (
        this.managementData.custom_input_yn &&
        this.__fixMaterial.length > 0
      ) {
        const calStock = this.$decimalAdd(
          this.$decimalSub(
            stock_quantity != undefined ? stock_quantity : 0,
            occupied_stock != undefined ? occupied_stock : 0,
          ),
          expect_stock != undefined ? expect_stock : 0,
        );

        return this.$makeNumber(
          this.$decimalSub(
            calStock,
            this.$decimalMul(
              this.$makeNumber(this.__fixMaterial[index].quantity),
              this.getUnitConversion(
                this.findInfoFromId(
                  this.materials,
                  this.group_material_list[index].material_id,
                ).incoming_unit_id,
                this.findInfoFromId(
                  this.materials,
                  this.group_material_list[index].material_id,
                ).using_unit_id,
              ),
            ),
          ),
        );
      } else {
        const calStock = this.$decimalAdd(
          this.$decimalSub(
            stock_quantity != undefined ? stock_quantity : 0,
            occupied_stock != undefined ? occupied_stock : 0,
          ),
          expect_stock != undefined ? expect_stock : 0,
        );

        return this.$makeNumber(
          this.$decimalSub(
            calStock,
            this.$decimalMul(
              this.calNeedQuantity(this.group_material_list[index], 1),
              this.getUnitConversion(
                this.findInfoFromId(
                  this.materials,
                  this.group_material_list[index].material_id,
                ).incoming_unit_id,
                this.findInfoFromId(
                  this.materials,
                  this.group_material_list[index].material_id,
                ).using_unit_id,
              ),
            ),
          ),
        );
      }
    },
    getProductCalQuantity(index) {
      const stock_quantity = this.findInfoFromId(
        this.product_stock_list,
        this.group_product_list[index].product_id,
      ).stock_quantity;

      const occupied_stock = this.findInfoFromId(
        this.product_occupied_stock,
        this.group_product_list[index].product_id,
      ).quantity;

      const expect = this.lots[
        this.selectedIndex
      ].lot_resource_expect_list.find(
        x => x.product_id == this.group_product_list[index].product_id,
      );
      let expect_stock = 0;
      if (expect != undefined) {
        expect_stock = expect.quantity;
      }
      if (this.managementData.custom_input_yn && this.__fixProduct.length > 0) {
        const calStock = this.$decimalAdd(
          this.$decimalSub(
            stock_quantity != undefined ? stock_quantity : 0,
            occupied_stock != undefined ? occupied_stock : 0,
          ),
          expect_stock != undefined ? expect_stock : 0,
        );
        const needQuantity = this.$makeNumber(
          this.__fixProduct[index].quantity,
        );

        return this.$makeNumber(
          this.$decimalSub(this.$makeNumber(calStock), needQuantity),
        );
      } else {
        const calStock = this.$decimalAdd(
          this.$decimalSub(
            stock_quantity != undefined ? stock_quantity : 0,
            occupied_stock != undefined ? occupied_stock : 0,
          ),
          expect_stock != undefined ? expect_stock : 0,
        );
        const needQuantity = this.$decimalMul(
          this.$makeNumber(this.group_product_list[index].quantity),
          this.$makeNumber(this.managementData.quantity),
        );

        return this.$makeNumber(
          this.$decimalSub(this.$makeNumber(calStock), needQuantity),
        );
      }
    },

    async submitForm() {
      if (
        this.managementData.lot_type_id !=
        this.lot_types.find(x => x.detail == 'wait process').id
      ) {
        this.openOneButtonModal(
          '수정 불가',
          '진행중 또는 완료된 작업지시는 수정할 수 없습니다.',
        );
        return;
      }
      if (!this.checkValid) {
        this.openOneButtonModal(
          '재고부족',
          '작업을 위한 재고가 부족하여<br />작업지시를 내릴 수 없습니다.',
        );
      } else {
        const payload = this.lodash.clonedeep(this.managementData);
        if (payload.lot_detail.length > 0) {
          payload.lot_detail = payload.lot_detail.map(x => {
            x.quantity = this.$makeNumber(x.quantity);
            return x;
          });
        }
        payload.lot_type_id = this.lot_types.find(
          x => x.detail == 'wait process',
        ).id;

        let resource_expect = [];

        if (payload.custom_input_yn) {
          const mat_group = this.lodash.clonedeep(this.__fixMaterial);
          const pro_group = this.lodash.clonedeep(this.__fixProduct);

          mat_group.forEach(el => {
            el.quantity = this.$makeNumber(el.quantity);
            resource_expect.push({
              material_id: el.material_id,
              product_id: null,
              quantity: this.$makeNumber(
                this.$decimalMul(
                  this.$makeNumber(el.quantity),
                  this.getUnitConversion(
                    this.findInfoFromId(this.materials, el.material_id)
                      .incoming_unit_id,
                    this.findInfoFromId(this.materials, el.material_id)
                      .using_unit_id,
                  ),
                ),
              ),
            });
          });
          pro_group.forEach(el => {
            resource_expect.push({
              material_id: null,
              product_id: el.product_id,
              quantity: this.$makeNumber(el.quantity),
            });
          });
        } else {
          const mat_group = this.lodash.clonedeep(this.group_material_list);
          const pro_group = this.lodash.clonedeep(this.group_product_list);

          mat_group.forEach(el => {
            el.quantity = this.$makeNumber(el.quantity);
            resource_expect.push({
              material_id: el.material_id,
              product_id: null,
              quantity: this.$makeNumber(
                this.$decimalMul(
                  this.calNeedQuantity(el, 1),
                  this.getUnitConversion(
                    this.findInfoFromId(this.materials, el.material_id)
                      .incoming_unit_id,
                    this.findInfoFromId(this.materials, el.material_id)
                      .using_unit_id,
                  ),
                ),
              ),
            });
          });
          pro_group.forEach(el => {
            resource_expect.push({
              material_id: null,
              product_id: el.product_id,
              quantity: this.$makeNumber(this.calNeedQuantity(el, 2)),
            });
          });
        }

        payload.lot_resource_expect_list = resource_expect;
        if (typeof payload.quantity == 'string') {
          payload.quantity = this.lodash.clonedeep(
            String(payload.quantity).replace(/,/gi, ''),
          );
        }

        console.log(payload);
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_LOT', payload)
          .then(async () => {
            this.$store.dispatch('UPDATE_LOT_TANK', this.managementLotTank);
            this.openOneButtonModal(
              '수정 성공',
              '작업지시서를 성공적으로 수정하였습니다.',
            );
            this.selectRow(-1);
            await this.resetForm();
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '수정 오류',
              '작업지시서 수정 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async resetForm() {
      await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '자재 현재고');
      await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '제품 현재고');
      await this.FETCH('FETCH_MATERIAL_OCCUPIED_STOCK', '자재 사용중 재고');
      await this.FETCH('FETCH_PRODUCT_OCCUPIED_STOCK', '제품 사용중 재고');
      await this.FETCH('FETCH_LOT_ALL', '작업지시');

      this.$store.commit('setManagementDataToWorkOrder', {
        input_date: null,
        deadline_date: null,
        product_id: null,
        bom_id: null,
        quantity: 0,
        sales_id: null,
        company_id: null,
        detail: '',
      });
      var date = new Date();
      this.managementData.input_date = yyyymmdd(date);
      var date2 = new Date(date.setDate(date.getDate() + 7));
      this.managementData.deadline_date = yyyymmdd(date2);
      this.$store.commit('setManagementSelectedSalesProducts', []);
    },

    pushManagementCompany(arg) {
      if (this.showCompanySearch) {
        this.showCompanySearch = false;
        this.$store.commit('setManagementSelectedSalesProducts', []);
        this.managementData.sales_id = null;
      }
      this.managementData.company_id = arg.id;
    },
    pushProductsToList(arg) {
      this.$store.commit('setManagementSelectedSalesProducts', []);
      arg.product_list.forEach(el => {
        //여기서 이미 등록되어있는지 아닌지 필터링
        el.bom_parents_id = el.id;
        el.total_quantity =
          el.box_unit_id != null
            ? this.$decimalMul(
                this.findInfoFromId(this.boxs, el.box_unit_id).quantity,
                el.quantity,
              )
            : el.quantity;
        this.selected_product_list.push(el);
        this.makeBomProduct(el);
      });

      this.showSalesSearch = false;
      let workorder_product_list = [];
      this.selected_product_list.forEach(element => {
        const search_el = workorder_product_list.find(
          x => x.product_id == element.product_id,
        );
        if (search_el != undefined) {
          search_el.total_quantity = this.$decimalAdd(
            search_el.total_quantity,
            element.total_quantity,
          );
        } else {
          let hit = this.lots.find(
            x =>
              x.sales_id == element.sales_id &&
              x.product_id == element.product_id,
          );
          if (hit == undefined) {
            workorder_product_list.push({
              sales_id: this.lodash.clonedeep(element.sales_id),
              product_id: this.lodash.clonedeep(element.product_id),
              total_quantity: this.lodash.clonedeep(element.total_quantity),
              company_id: this.lodash.clonedeep(arg.company_id),
            });
          }
        }
      });
      console.log(this.selected_product_list);
      this.$store.commit(
        'setManagementSelectedSalesProducts',
        this.lodash.clonedeep(workorder_product_list),
      );

      this.managementData.sales_id = this.selected_product_list[0].sales_id;
      this.managementData.company_id = this.selected_product_list[0].company_id;
      this.managementData.quantity = this.selected_product_list[0].total_quantity;
      this.selectProduct({ id: this.selected_product_list[0].product_id });
    },
    makeBomProduct(el) {
      //bom안에 제품이 또 포함되어 있다면 추가한다.
      const bom = this.boms.find(x => x.product_id == el.product_id);
      if (bom != undefined) {
        const el_unit_name = this.findInfoFromId(this.units, el.unit_id).name;
        const el_product_name = this.findInfoFromId(
          this.products,
          el.product_id,
        ).name;
        bom.bom_process.forEach(process => {
          process.bom_resource.forEach(product => {
            if (product.product_id != null) {
              const product_unit_name = this.findInfoFromId(
                this.units,
                this.findInfoFromId(this.products, product.product_id)
                  .stock_unit_id,
              ).name;
              const product_name = this.findInfoFromId(
                this.products,
                product.product_id,
              ).name;
              const new_element = {
                box_unit_id: null,
                id: this.bom_parents_id_count,
                product_id: product.product_id,
                quantity: product.quantity,
                total_quantity: this.$decimalMul(
                  product.quantity,
                  el.total_quantity,
                ),
                unit_id: this.findInfoFromId(this.products, product.product_id)
                  .stock_unit_id,
                sales_id: el.sales_id,
                product_label: el_product_name + ' >> ' + product_name,
                bom_parents_id: el.bom_parents_id,
                quantity_label: `${el.total_quantity}(${el_unit_name}) x ${
                  product.quantity
                }(${product_unit_name}) = ${this.$decimalMul(
                  product.quantity,
                  el.total_quantity,
                )}(${product_unit_name})`,
              };
              this.selected_product_list.push(new_element);
              this.bom_parents_id_count--;
              this.makeBomProduct(new_element);
            }
          });
        });
      }
    },
    goBomManagementPage(product_id) {
      if (this.$route.path != '/code/bom') {
        const goingPage = routes[0].children.find(x => x.path == '/code/bom');
        if (goingPage != undefined) {
          console.log('log product_id', product_id);
          goingPage.meta.select = product_id;
        }
        this.$router.push('/code/bom');
      }
    },
    handleProductInput(e) {
      this.product_search_text = e.target.value;
    },
    async setInputDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.managementData.deadline_date) {
          this.openOneButtonModal(
            '작업지시일 오류',
            `작업지시일(${this.managementData.deadline_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.input_date;
        } else {
          this.managementData.input_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        e.target.value = this.input_date;
      }
    },
    async setDeadLineDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.managementData.input_date) {
          this.openOneButtonModal(
            '작업완료일정 오류',
            `작업완료일정(${this.managementData.input_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.deadline_date;
        } else {
          this.managementData.deadline_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        e.target.value = this.deadline_date;
      }
    },
    selectProduct(arg) {
      this.$store.commit('setManagementGroupMaterialToWorkOrder', []);
      if (this.showProductSearch) {
        this.showProductSearch = false;
        this.$store.commit('setManagementSelectedSalesProducts', []);
        this.managementData.company_id = null;
        this.managementData.quantity = 0;
        this.managementData.sales_id = null;
      }

      if (this.getBomFromProductId(arg.id) == null) {
        this.openOneButtonModal(
          'BOM정보 없음',
          '선택한 제품의 BOM이 등록되어있지 않습니다.<br />BOM을 먼저 등록하여주십시오.',
        );
        // this.goBomManagementPage(arg.id);
        return;
      }

      if (this.findInfoFromId(this.products, arg.id).product_group_yn) {
        let findMemberProducts = this.lodash
          .clonedeep(this.product_groups)
          .filter(x => x.group_product_id == arg.id);
        console.log('findMemberProducts', findMemberProducts);
        if (findMemberProducts.length > 0) {
          findMemberProducts = findMemberProducts.map(x => {
            const foundBeforeLotDetail = this.lodash
              .clonedeep(this.managementData.lot_detail)
              .find(y => y.member_product_id == x.member_product_id);
            x.quantity =
              foundBeforeLotDetail != undefined
                ? foundBeforeLotDetail.quantity
                : 0;

            return x;
          });
          this.managementData.lot_detail = findMemberProducts;
        }
      }
      this.managementData.product_id = arg.id;
      this.managementData.bom_id = this.getBomFromProductId(arg.id).id;
    },
    selectProductFromSelectRow(arg) {
      this.$store.commit('setManagementGroupMaterialToWorkOrder', []);
      if (this.showProductSearch) {
        this.showProductSearch = false;
        this.$store.commit('setManagementSelectedSalesProducts', []);
        this.managementData.company_id = null;
        this.managementData.quantity = 0;
        this.managementData.sales_id = null;
      }

      console.log('arg.id', arg.id);
      const foundBom = this.lodash
        .clonedeep(this.boms)
        .find(x => x.product_id == arg.id);
      if (foundBom == undefined) {
        this.openOneButtonModal(
          'BOM정보 없음',
          '선택한 제품의 BOM이 등록되어있지 않습니다.<br />BOM을 먼저 등록하여주십시오.',
        );
        this.goBomManagementPage(arg.id);
        return;
      }
      if (this.findInfoFromId(this.products, arg.id).product_group_yn) {
        let findMemberProducts = this.lodash
          .clonedeep(this.product_groups)
          .filter(x => x.group_product_id == arg.id);

        if (findMemberProducts.length > 0) {
          findMemberProducts = findMemberProducts.map(x => {
            const foundBeforeLotDetail = this.lodash
              .clonedeep(this.managementData.lot_detail)
              .find(y => y.member_product_id == x.member_product_id);
            x.quantity =
              foundBeforeLotDetail != undefined
                ? foundBeforeLotDetail.quantity
                : 0;

            return x;
          });
          this.managementData.lot_detail = findMemberProducts;
        }
      }
      // this.managementData.product_id = arg.id;
      // this.managementData.bom_id = this.getBomFromProductId(arg.id).id;
    },
    getSellerCompanyFromProductId(id) {
      const hit = this.seller_companys.filter(x => x.product_id == id);
      return hit;
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: '1950-01-01',
          end_date: '2500-01-01',
          check_only_not_completed: true,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    getBomFromProductId(id) {
      if (id == undefined || id == null) {
        return null;
      }
      const hit = this.boms.find(x => x.product_id == id);
      return hit != undefined ? hit : null;
    },
    getUnitConversion(input, output) {
      if (input == output) return 1;
      const hit = this.unit_conversion.find(
        x => x.in_unit_id == input && x.out_unit_id == output,
      );
      return hit != undefined ? hit.cvr_ratio : 1;
    },
    FETCH_LOT_DAY() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_LOT_DAY', {
          start: this.start_date,
          end: this.end_date,
        })
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '조회 중 오류',
            '작업지시 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
  },
  watch: {
    managementData(newValue) {
      const expectTemp = this.lodash.clonedeep(
        newValue.lot_resource_expect_list,
      );
      const group_material_list_temp = this.lodash.clonedeep(
        this.group_material_list,
      );
      const group_product_list_temp = this.lodash.clonedeep(
        this.group_product_list,
      );

      this.$store.commit(
        'setManagementFixExpectMaterial',
        group_material_list_temp.map(x => {
          const findExpect = expectTemp.find(
            y => y.material_id == x.material_id,
          );
          if (findExpect != undefined) {
            const material = this.findInfoFromId(this.materials, x.material_id);
            if (material != undefined) {
              const cvr_ratio = this.getUnitConversion(
                material.incoming_unit_id,
                material.using_unit_id,
              );
              if (cvr_ratio != undefined && cvr_ratio != 1) {
                x.quantity = this.$decimalDiv(findExpect.quantity, cvr_ratio);
              } else {
                x.quantity = findExpect.quantity;
              }
            } else {
              x.quantity = findExpect.quantity;
            }
          }
          return x;
        }),
      );
      this.$store.commit(
        'setManagementFixExpectProduct',
        group_product_list_temp.map(x => {
          const findExpect = expectTemp.find(y => y.product_id == x.product_id);
          if (findExpect != undefined) {
            x.quantity = findExpect.quantity;
          }
          return x;
        }),
      );
    },
  },
  async created() {
    if (this.lot_types.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '작업지시 상태');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.unit_conversion.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.seller_companys.length < 1) {
      await this.FETCH('FETCH_SELLER_COMPANY', '제품 매출처');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.materialGroups.length < 1) {
      await this.FETCH('FETCH_MATERIAL_GROUP', '자재 그룹');
    }
    if (this.product_groups.length < 1) {
      await this.FETCH('FETCH_PRODUCT_GROUP', '제품 그룹');
    }

    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '자재 현재고');
    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '제품 현재고');
    await this.FETCH('FETCH_MATERIAL_OCCUPIED_STOCK', '자재 사용중 재고');
    await this.FETCH('FETCH_PRODUCT_OCCUPIED_STOCK', '제품 사용중 재고');

    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setEndDateToWorkOrder', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToWorkOrder', yyyymmdd(date2));
    }
    await this.FETCH_LOT_DAY();
    await this.FETCH_SALES();

    if (
      this.managementData.id != null &&
      this.findInfoFromId(this.boms, this.managementData.bom_id).product_id ==
        null
    ) {
      const select = this.lodash.clonedeep(
        this.lots.find(x => x.id == this.managementData.id),
      );
      await this.$store.commit('setManagementDataToWorkOrder', select);
      if (this.managementData.lot_type_id == 1) {
        this.selectProduct({ id: this.managementData.product_id });
      } else {
        this.selectProductFromSelectRow({
          id: this.managementData.product_id,
        });
      }
    } else if (this.selectedIndex != -1) {
      const findIndex = this.lots.findIndex(
        x => x.id == this.managementData.id,
      );
      this.selectRow(findIndex);
    }

    this.showSearchDate = true;
  },
};
</script>

<style lang="scss" scoped></style>
